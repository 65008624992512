import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Link } from "gatsby";

export default (props) => (
  <StaticQuery
    query={graphql`
    query ImpactCardList {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(impact-stories)/"}}, sort: {order: ASC, fields: frontmatter___order}) {
        nodes {
          frontmatter {
            path
            name
            quote
            personTitle
            thumbnailImage {
              publicURL
            }
          }
        }
      }
    }
    `}
    render={data => {
      
      const stories = data.allMarkdownRemark.nodes;

      var storylist = stories.map(function(story){
        return (

          <div className="impact-story">
            <div className="row">
              <div className="col-4">
                <Link to={story.frontmatter.path}>
                  <img src={story.frontmatter.thumbnailImage.publicURL} alt={story.frontmatter.name} />
                </Link>
              </div>
              <div className="col-8 p-20">
                <blockquote>
                  <p>“{story.frontmatter.quote}”</p>
                  <cite>{story.frontmatter.name}, {story.frontmatter.personTitle}</cite>
                </blockquote>
                <Link to={story.frontmatter.path} className="button pill orange">Read More</Link>
              </div>
            </div>
          </div>

        )
      })

      return storylist;
      
    }}
  />
)