import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import DOD from '../components/dods';
import impactStoryList from "../components/impact-story-list";
import ImpactStoryList from "../components/impact-story-list";

var pageTitle = 'Impact Stories';

var pageDesc = 'Cal State Fullerton is delivering on the promise of higher education. We are the catalyst for Titan-sized transformations, inspiring students’ paths, launching careers and improving lives both on campus and in the community.';

export default (props) => (
  <React.Fragment>
  <Layout locationInfo={props.location}>
    <Helmet>
      <title>{pageTitle}</title>
      <body className="impact-stories" />

      <meta name="description" content={pageDesc} />

      {/* Schema.org markup for Google+ */}
      <meta itemprop="name" content={pageTitle} />
      <meta itemprop="description" content={pageDesc} />

      {/* Twitter Card data */}
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDesc} />

      {/* Open Graph data */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDesc} />
    </Helmet>
    <section className="p-t-150 p-b-100 background-beige">
      <article className="wrap small">

        <h1 className="center">{pageTitle}</h1>

        <p>Students share, in their own words, how they were empowered by their Titan education. Thanks to you, our generous community of donors, these students discovered their passions and leadership potential in academics, arts and athletics.</p>
 
      </article>
    </section>
    <section className="p-t-100 p-b-100 background-white">
      <div className="wrap impact-stories">

        <ImpactStoryList />
 
      </div>
    </section>
    <DOD />
  </Layout>
  </React.Fragment>
);
